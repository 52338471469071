<template>
  <v-dialog
    v-model="shower"
    persistent
    :max-width="widthDialog"
    style="overflow-x: hidden"
  >
    <v-card>
      <v-card-title dark :style="headerDialog">
        <span
          class="font-weight-bold white--text text-center"
          style="font-size: 18px"
        >
          {{ status === 'WARNING' ? $t('otp_botonebox.sessionwill') : $t('otp_botonebox.sessiontimeout') }}
        </span>
        <v-spacer></v-spacer>
        <v-icon dark :disabled="isloading" @click="closedialog_expired"> mdi-close </v-icon>
      </v-card-title>
      <v-card-text
        class="mt-6 pt-3 text-center"
        v-if="isContinue === false"
      >
        <p class="text-subtitle-1 font-weight-bold">{{ status === 'WARNING' ? `${$t('otp_botonebox.your_session_timeout_in')} ${leftTime} ${$t('otp_botonebox.minutes')}` : $t('otp_botonebox.your_session_timeout') }}<br /></p>
        <span class="text-subtitle-2">
          {{ $t('otp_botonebox.otp_detail') }}
        </span>
        <br />
        <div class="pt-3">
          <span class="text-h6" style="font-size: 26px;">
          ({{ actual_username }})
        </span>
        </div>
      </v-card-text>

      <v-card-text class="mt-6 pt-3" v-else>
        <v-form ref="form" :disabled="this.isSendOtp">
          <v-text-field
            class="font-weight-small"
            clear-icon="mdi-close"
            :hint="'Email or username'"
            clearable
            disabled
            :rules="[rules.required]"
            v-model.trim="actual_username"
            label="Username"
            outlined
            dense
            :error-messages="actually_username"
            @blur="$v.actual_username.$touch()"
          >
          </v-text-field>
        </v-form>

        <div  v-if="isSendOtp === true">
          <v-divider></v-divider>
          <v-flex
            dlex
            class="pb-5 justify-center align-center mt-5"
          >
            <div>
                <div class="mt-n2 pb-2">
                  <span class="subtitle-2">
                    {{ $t('otp_botonebox.enter_otp') }} :
                  </span>
                </div>
                <div class="testForm" v-if="isResendOTPImmi === false">
                  <otp-input
                    class="testotp"
                    ref="otpVerifyRefs"
                    @on-complete="onCompleteOTP"
                    @on-changed="onChangedOTP"
                    @on-paste="onPasteOTP"
                    :separateInputClass="otpclass"
                    :autoFocus="false"
                    activeWrapperClass="mai-finished"
                    placeholder=""
                    :isDisabled="isOtptimeout || isloading"
                    :isValid="false"
                    :digits="6"
                    type="text"
                  >
                  </otp-input>
                </div>
                <div v-else class="py-2 text-center">
                    <v-progress-circular
                      color="teal"
                      indeterminate
                      size="20"
                      width="3"
                    >

                    </v-progress-circular>
                </div>
                <div
                  v-if="this.checkIsvalidOTP === false"
                  d-flex
                  class="mt-2 text-center"
                  style="background-color: rgb(230, 230, 230)"
                >
                  <div class="align-center justify-center">
                    <v-icon color="warning"> mdi-alert-circle </v-icon>
                    <span class="text-subtitle-2 font-weight-bold ml-1">
                      {{ messageerrorotp }}
                    </span>
                  </div>
                </div>
            </div>
            <div class="align-self-end mt-3 pb-0 d-flex">
              <span :class="coveredTimeFormat_style">
                {{ coverTimeFormat }}
              </span>
              <v-spacer></v-spacer> 
              <v-icon @click="resendOTPimmediate"
               :disabled="isloading" v-if="!isOtptimeout">
                mdi-refresh
              </v-icon>
            </div>
          </v-flex>
        </div>

      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions v-if="isContinue === false">
        <v-spacer></v-spacer>
        <v-btn depressed color="error" 
        @click="closedialog_expired"
        :style="btnAction" :width="widthResendBtn">
          <span class="subtitle-2">  {{ status === 'WARNING' ? $t('otp_botonebox.cancel_otp_btn') : $t('otp_botonebox.logout_otp_btn') }} </span>
        </v-btn>
        <v-btn
          depressed
          :color="color.theme"
          dark
          :style="btnAction"
          @click="isContinue = true"
          :width="widthResendBtn"
        >
          <span class="subtitle-2"> {{$t('otp_botonebox.continue_otp_btn')}} </span>
        </v-btn>  
      </v-card-actions>
      <v-card-actions v-else-if="isContinue === true && isSendOtp === false">
        <v-spacer></v-spacer>
        <v-btn depressed 
        color="error" 
        :disabled="isloading"
        @click="closedialog_expired"
        :style="btnAction" :width="widthResendBtn">
          <span class="subtitle-2"> {{$t('otp_botonebox.cancel_otp_btn')}} </span>
        </v-btn>
        <v-btn
          depressed
          :color="color.theme"
          :dark="isloading ? false : true"
          :style="btnAction"
          @click="checkvalidusername"
          :width="widthResendBtn"
          :disabled="isloading"
        >
          <v-progress-circular
            v-if="isloading"
            indeterminate
            color="white"
            size="14"
            class="mr-1 ml-2"
          ></v-progress-circular>
          <span class="subtitle-2"> {{$t('otp_botonebox.send_otp_btn')}} </span>
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else-if="isSendOtp === true || isOtptimeout === true">
        <v-spacer></v-spacer>
        <v-btn depressed color="error" 
          :disabled="isloading"
          @click="closedialog_expired"
          :style="btnAction" :width="widthResendBtn">
          <span class="subtitle-2"> {{$t('otp_botonebox.cancel_otp_btn')}} </span>
        </v-btn>
        <v-btn
          depressed
          :color="color.theme"
          :dark="isloading ? false : true"
          :style="btnAction"
          :width="widthResendBtn"
          v-if="isOtptimeout"
          @click="fn_resend_otp"
        >
          <v-progress-circular
            v-if="isloading"
            indeterminate
            color="white"
            size="14"
            class="mr-1"
          ></v-progress-circular>
          <v-icon small class="mr-1" v-else> mdi-replay </v-icon>
          <span class="subtitle-2"> {{ $t('otp_botonebox.resend_otp_btn') }} </span>
        </v-btn>
        <v-btn
          depressed
          :color="color.theme"
          :dark="isloading ? false : true"
          :style="btnAction"
          :disabled="isloading"
          v-if="!isOtptimeout"
          :width="widthResendBtn"
          @click="checkvalidotp"
        >
          <span class="subtitle-2"> {{ $t('otp_botonebox.verify_otp') }} </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import VueCookies from "vue-cookies";
import OtpInput from "otp-input-vue2";
import { validationMixin } from "vuelidate";
import formatDatetime from "../../globalFunctions/formatDatetime";
import { required } from "vuelidate/lib/validators";
import CryptoJS from "crypto-js";
export default {
  mixins: [validationMixin],
  validations: {
    actual_username: { required },
  },
  components: {
    OtpInput,
  },
  props: ["showExpired", "status"],
  computed: {
    ...mapState([
      "color",
      "status_permission_outbox",
      "status_permission_inbox",
      "feature_status",
      "role_level",
      "storage_usage",
      "storage_usage_biz",
      "storage_max_personal_biz",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters({
      dataBusinessProfile: "dataBusinessProfile",
      dataAccesstoken: "dataAccesstoken",
    }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataAccountCCTV",
      "dataStorageMaxpersonalinbiz",
      "dataLoginDefault",
      "dataCheckPackage",
    ]),
    widthDialog() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 340;
        default:
          return 400;
      }
    },
    headerDialog() {
      console.log("this. color ", this.color.theme);
      return `background-color: ${this.color.theme}`;
    },
    widthResendBtn() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "40%";
        default:
          return "35%";
      }
    },
    widthDialog() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 340;
        default:
          return 400;
      }
    },
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    otpclass() {
      if (this.checkIsvalidOTP === true) {
        console.log("True ");
        return "mai-classsed";
      } else {
        console.log("Falsde ");
        return "mai-classsed error-class";
      }
    },
    coveredTimeFormat_style() {
      if (this.isOtptimeout) {
        return "subtitle-2 red--text";
      } else {
        return `subtitle-2`;
      }
    },
    leftTime () {
      return this.diff_minutes(new Date(), new Date(this.decryptdata(VueCookies.get("login_datetime"))).getTime())
    },
    
    coverTimeFormat() {
      if (this.isOtptimeout) {
        return "OTP Time out";
      } else {
        return `Time Remaining : ${this.secondsToMinSec(
          this.countdowntime_otp
        )}`;
      }
    },
    actually_username() {
      const errors = [];
      if (this.checkIsvalidUsername === false) {
        errors.push(this.messageerrorusername);
        return errors;
      } else {
        if (!this.$v.actual_username.$dirty) return errors;
        !this.$v.actual_username.required &&
          errors.push("โปรดระบุชื่อผู้ใช้จริง / Please fill username");
        return errors;
      }
    },

    shower: {
      get() {
        if (this.showExpired === true) {
          this.showDialogExpired = this.showExpired;
          return this.showDialogExpired;
        } else {
          this.showDialogExpired = this.showExpired;
          return this.showDialogExpired;
        }
      },
    },
  },

  data: function () {
    return {
      showDialogExpired: false,
      isContinue: false,

      //From OTP dialog
      data_id_otp: "",
      actual_username: "",
      reason_login: "",
      otp_verify: "",
      messageerrorotp: "",
      messageerrorusername: "",
      isResendOTPImmi: false,
      isSendOtp: false,
      isOtptimeout: false,
      isCompleteOtp: false,
      checkIsvalidOTP: true,
      checkIsvalidUsername: true,
      isloading: false,
      interval: null,
      beforeUnloadListener: null,
      showdialogOpt: false,
      countdowntime_otp: 60 * 5,
      countdowntime_otp_format: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  methods: {
    encryptdata (raw_data) {
      let enc = CryptoJS.AES.encrypt(window.btoa(raw_data), 
      CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),{ mode: CryptoJS.mode.ECB, }).toString()
      return enc
    },

    decryptdata (data) {
      var data_decrypt = CryptoJS.AES.decrypt(data, 
          CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      data_decrypt = window.atob(data_decrypt.toString(CryptoJS.enc.Utf8)) 
      return data_decrypt
    },

    async resendOTPimmediate () {
      this.isloading = true
      this.isResendOTPImmi = true
      if(this.data_id_otp !== "" ){
        clearInterval(this.interval)
        let payload = { otp: "", status: "N", data_id: this.data_id_otp };
        let result = await this.fn_cancel_otp(payload);
        if (result.status === "OK") {
          this.data_id_otp = ""
          this.fn_resend_otp()
        }
      }
    },
    diff_minutes(dt2, dt1) {
      var diff = (dt2.getTime() - dt1) / 1000;
      diff /= 60;
      return Math.abs(Math.round(diff));
    },
    async closedialog_expired() {
      if(this.status === "WARNING"){
        if(this.isSendOtp === true){
          clearInterval(this.interval)
          if (this.isOtptimeout === true) {
            VueCookies.set("continue_otp", true)
            this.$emit("closecontinue")
            return;
          }
          let payload = { otp: "", status: "N", data_id: this.data_id_otp };
          let result = await this.fn_cancel_otp(payload);
          if (result.status === "OK") {
            this.data_id_otp = ""
            VueCookies.set("continue_otp", true)
            this.$emit("closecontinue")
          }
        } else {
          VueCookies.set("continue_otp", true)
          //ต้องแก้ตรง VUEX ด้วย
          this.$emit("closecontinue")
        }
      } else {
        if(this.isSendOtp === true){
          clearInterval(this.interval)
          if (this.isOtptimeout === true) {
            this.$emit("logoutbotonebox");
            return;
          }
          let payload = { otp: "", status: "N", data_id: this.data_id_otp };
          let result = await this.fn_cancel_otp(payload);
          if (result.status === "OK") {
            this.data_id_otp = ""
            this.$emit("logoutbotonebox");
          }
        } else {
          this.$emit("logoutbotonebox")
        }
      }
    },

    startCountdown() {
      this.interval = setInterval(async () => {
        if (this.countdowntime_otp > 0) {
          this.countdowntime_otp--;
        } else {
          // Handle any logic when the countdown reaches zero
          clearInterval(this.interval);
          this.isOtptimeout = true;
          this.isloading = true;
          let payload = {
            otp: "",
            status: "N",
            data_id: this.data_id_otp,
          };
          let result = await this.fn_cancel_otp(payload);
          if (result.status === "OK") {
            this.isloading = false;
          }
          // let result = await this.fn_cancel_otp(payload);
          // if (result.status === "OK") {
          //   this.isloading = false;
          // }
        }
      }, 1000);
    },
    

    secondsToMinSec(second) {
      const minutes = `${Math.floor(second / 60)}`.padStart(2, "0");
      const seconds = `${second - minutes * 60}`.padStart(2, "0");
      return `${minutes}:${seconds}`;
    },
    //VALID USERNAME
    onCompleteOTP(otpValue) {
      this.isCompleteOtp = true;
      this.otp_verify = otpValue;
    },
    onChangedOTP() {
      this.isCompleteOtp = false;
    },
    onPasteOTP (pasteValue) {
      this.isCompleteOtp = true;
      this.otp_verify = pasteValue
    },
    onErrorMessageOtp(msg) {
      this.checkIsvalidOTP = false;
      this.messageerrorotp = msg;
      setTimeout(() => {
        this.checkIsvalidOTP = true;
        this.messageerrorotp = "";
      }, 2000);
    },

    async checkvalidusername() {
      if (this.$v.actual_username.$invalid) {
        console.log("Check Invalid ", this.$v.actual_username.$invalid);
        this.$v.$touch();
        return;
      }
      this.isloading = true;
      let payload = {
        user: this.actual_username, //username from receive otp
        reason_access: decodeURIComponent(escape(atob(VueCookies.get("reason_otp")))), //reason to get in
      };
      await this.fn_send_otp(payload)
        .then((ress) => {
          if (ress.status === "OK") {
            // initial new time
            if (this.isOtptimeout === true) {
              this.isOtptimeout = false;
            }
            if(this.isResendOTPImmi === true){
              this.isResendOTPImmi = false
            }
            this.countdowntime_otp = 60 * 5;
            (this.isloading = false), (this.isSendOtp = true);
            this.data_id_otp = ress.data_id;
            this.startCountdown();
          } else if (
            ress.status === "NOT FOUND" ||
            ress.status === "NOT ADD FRIEND"
          ) {
            this.isloading = false;
            this.checkIsvalidUsername = false;
            this.messageerrorusername = ress.message;
            setTimeout(() => {
              this.checkIsvalidUsername = true;
              this.messageerrorusername = "";
            }, 1500);
          } else {
            this.isloading = false;
            this.checkIsvalidUsername = false;
            this.messageerrorusername = "Something wrong, Please try again";
            setTimeout(() => {
              this.checkIsvalidUsername = true;
              this.messageerrorusername = "";
            }, 1500);
          }
        })
        .catch((err) => {
          console.log("Error Axios ", err);
        });
    },
    async checkvalidotp() {
      if (this.otp_verify === "" || this.isCompleteOtp === false) {
        this.onErrorMessageOtp(this.$t("otp_botonebox.fill_otp"));
        return;
      }

      this.isloading = true;
      let payload = {
        otp: this.otp_verify,
        status: "Y",
        data_id: this.data_id_otp,
      };
      await this.fn_verify_otp(payload).then((res) => {
        if (res.status === "OK") {
          if(sessionStorage.getItem("checklogin") !== null){ // set remember me as default
            sessionStorage.setItem("token_auth", true);
          } else {
            VueCookies.set("token_auth", true, 60 * 60);
          }
          
          VueCookies.set("continue_otp", false)
          VueCookies.set("login_datetime", this.encryptdata(formatDatetime.formatdatetime_for_create_newdate(res.time_using)))
          VueCookies.set("data_id", this.data_id_otp) // set data_id ใหม่
          clearInterval(this.interval);
          this.$emit("closecontinue")
        } else if (res.status === "INVALID OTP") {
          this.isloading = false;
          this.onErrorMessageOtp(this.$t("otp_botonebox.invalid_otp"));
        }
      });
    },

    async fn_send_otp(payload) {
      return await this.axios
        .post(process.env.VUE_APP_SERVICE_AUTHORIZE_CITIZEN + "/api/v1/sent/otp", payload)
        .then((response) => {
          return new Promise((resolve, reject) => {
            console.log("Response ", response);
            if (response.data.status === "OK") {
              setTimeout(() => {
                resolve({
                  status: "OK",
                  data_id: response.data.data_id,
                  message: "",
                });
              }, 500);
            } else {
              if (response.data.errorCode === "ER205") {
                setTimeout(() => {
                  resolve({
                    status: "NOT FOUND",
                    message: "ไม่พบเจอผู้ใช้นี้ใน one id",
                  });
                }, 500);
              } else if (response.data.errorCode === "ER203") {
                setTimeout(() => {
                  resolve({
                    status: "NOT ADD FRIEND",
                    message: "ผู้ใช้นี้ยังไม่ได้เพิ่ม chatbot",
                  });
                }, 500);
              } else {
                setTimeout(() => {
                  resolve({ status: "ERROR", message: "" });
                }, 500);
              }
            }
          });
        })
        .catch((err) => {});
    },
    async fn_resend_otp() {
      this.checkvalidusername();
    },

    async fn_verify_otp(payload) {
      return await this.axios
      .post(process.env.VUE_APP_SERVICE_AUTHORIZE_CITIZEN + "/api/v1/verify/otp", payload)
      .then((response) => {
        return new Promise((resolve, reject) => {
          if (response.data.status === "OK") {
            setTimeout(() => {
              resolve({ status: "OK", message: "Verified", time_using: response.data.expired_login_dtm });
            }, 500);
          } else {
            setTimeout(() => {
              resolve({ status: "INVALID OTP", message: "Invalid OTP provided" });
            }, 500);
          }
        });
      })
      .catch((err) => {})
    },

    async fn_cancel_otp(payload) {
      return await this.axios
        .post(process.env.VUE_APP_SERVICE_AUTHORIZE_CITIZEN +"/api/v1/verify/otp", payload)
        .then((res) => {
          if (res.data.status === "OK") {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve({ status: "OK", msg: "Cancel success" });
              }, 500);
            });
          }
        });
    },
  },
  mounted () {
    // เก่า
    //this.actual_username = this.decryptdata(localStorage.getItem("username_otp"))
    this.actual_username = this.decryptdata(VueCookies.get("username_otp"))
  }
};
</script>

<style scoped>
.vue-otp-input >>> .mai-classsed {
  text-align: center;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border: solid 1px rgba(128, 128, 128, 0.7);
  /*  */
}
.vue-otp-input >>> .error-class {
  border: solid 2px rgba(255, 125, 125, 255);
}
/* SCREEN SIZE XS  */
@media screen and (max-width: 350px) {
  .vue-otp-input >>> .mai-classsed {
    width: 30px;
    height: 35px;
  }
}
@media (min-width: 350px) and (max-width: 370px) {
  .vue-otp-input >>> .mai-classsed {
    width: 35px;
    height: 35px;
  }
}

@media (min-width: 370px) and (max-width: 385px) {
  .vue-otp-input >>> .mai-classsed {
    width: 38px;
    height: 35px;
  }
}

@media screen and (min-width: 600px) {
  .vue-otp-input >>> .mai-classsed {
    width: 48px;
    height: 40px;
  }
}
</style>
